import { replaceLineBreaks, boldText } from '../../util/element';
import { CATEGORY, SERIES, VALUE, POINT } from '../../data/constants';
import { formatQText, createLongCategories } from '../../data/processCategories';
import * as format from '../../data/format';
import datetime from '../../data/datetime';
import { arrowWidth } from './defs';
import { hasVideoGroup, hasWSJVideoGroup, hasZT } from '../utils';

export const preRender = (data, options) => {
  const isVideoGroup = hasVideoGroup(options.id);
  const shapeOptions = getShapeOptions();
  const labelOptions = getLabelOptions(options, isVideoGroup);
  const [labels, shapes, extraSpace] = createAnnotations(options, isVideoGroup);
  const visible = !hasZT(options.id);
  if (visible) addSpacing(data, options, extraSpace);
  data.annotations.push({
    labels,
    labelOptions,
    shapes,
    shapeOptions,
    visible,
  });
};

function getShapeOptions() {
  return {
    // fill: COLOR,
    // stroke: COLOR,
    type: 'circle',
    strokeWidth: 0,
  };
}

function getLabelOptions(options, isVideoGroup) {
  return {
    // backgroundColor: 'rgba(0, 0, 0, 0)', // defaults to gray, must be made transparent
    borderWidth: 0, // defaults to a border, which must be removed
    padding: 0, // label sits on top line before y applied
    y: setY(options, isVideoGroup), // margin above top line
    overflow: 'none', // allow labels rendered outside plot area
    allowOverlap: true, // keep highcharts from hiding labels; spacing handled in postrender
  };
}

function isWsjDigital(options) {
  return (options.product === 'wsj' || options.product === 'djriskjournal') && !options.print;
}

function setY(options, isVideoGroup) {
  if (options.print) return -16;
  // shift labels up 10px when suffix or recession label enabled
  const { id, axes } = options;
  const isJap = options.headings.chartFont === 'ja' ? -5 : 0;
  const { units, showRecessions, showUkRecessions } = axes;
  const offset = isVideoGroup ? 30 : isJap;
  const y = units.suffix.length > 2 || showRecessions || showUkRecessions ? -20 : -10;
  return y - offset;
}

function createAnnotations(options, isVideoGroup) {
  const labels = [];
  const shapes = [];
  const lineHeight = getLineHeight(options, isVideoGroup);
  const spacing = {};
  let maxHeight = 0;
  // extra space needed for tallest annotation
  const offset = isVideoGroup ? 1 : 0;

  options.annotations.forEach((item) => {
    // create label
    const { flushAlign, showLine, lineType } = item;
    const style = { flushAlign, showLine, lineType }; // info needed in postrender

    const formattedText = formatText(item, options).map((text) =>
      hasWSJVideoGroup(options) ? text.toUpperCase() : text
    );

    const text = formattedText.join('<br>');

    const [x, y, yAxis, endPoints, ms] = getXY(item, options);

    const label = { point: { x, y: 0, xAxis: 0 }, text, style }; // position at chart top

    // create corresponding shape
    const shape = createShape(item, options, x, y, yAxis, isVideoGroup);

    labels.push(label);
    shapes.push(shape);
    const numLines = (lastIdx(formattedText) + offset) * lineHeight;
    maxHeight = Math.max(maxHeight, numLines);

    // extra space needed for shape on right or left
    if (item.lineEnd !== 'nothing') {
      findMinMax(options, item, spacing, endPoints, ms);
    }
  });
  const { min, max } = spacing;
  return [labels, shapes, { top: maxHeight, min, max }];
}

function getLineHeight(options) {
  const { styles } = options;
  return styles.annotationsLineHeight || styles.lineHeight;
}

function formatText(item, options) {
  const text = compileText(item, options);
  const textBr = replaceLineBreaks(text);
  const lines = textBr.split('<br>');
  const wsjDigital = isWsjDigital(options);
  const [openTag, closeTag] = wsjDigital
    ? [`<span style="font-weight:500">`, '</span>']
    : ['<strong>', '</strong>'];
  const boldAnnotation = boldText();
  return lines.map((line) => boldAnnotation(line, openTag, closeTag));
}

function compileText(item, options) {
  const { text, category, series, value } = item;
  const isVideo = hasVideoGroup(options.id);
  const updatedSeries = isVideo ? series.toUpperCase() : series;
  return (text || '')
    .replace(/\\{/g, '__LEFT_BRACKET__')
    .replace(/\\}/g, '__RIGHT_BRACKET__')
    .replace(CATEGORY, compiledCategory(category.toString(), options))
    .replace(SERIES, updatedSeries)
    .replace(VALUE, value)
    .replace(POINT, compiledPoint(value, options, isVideo))
    .replace(/__LEFT_BRACKET__/g, '{')
    .replace(/__RIGHT_BRACKET__/g, '}');
}

function compiledCategory(category, options) {
  return options.categoriesAreDates ? formatDate(category, options) : category;
}

function formatDate(category, options) {
  const value = findValue(category, options);
  const dateObj = options.dates.find((date) => date.value === value);
  const formattedCategory = createLongCategories(options, [dateObj]);
  return formattedCategory[0];
}

function parseDate(category, options) {
  return datetime.parse([category], options);
}

function compiledPoint(value, options, isVideo) {
  if (isFinite(value)) {
    const { tooltips, type } = options;
    return format.applyFull(value, tooltips, true, true, false, false, isVideo);
  }
  return 'n/a';
}

function getXY(item, options) {
  let dataIdx;
  let x;
  const { category, series: seriesName, attachTo } = item;
  const { series, categories, irregularDates } = options;
  const { data } = findSeries(series, seriesName);
  if (data[0].hasOwnProperty('x')) {
    const value = findValue(category, options);
    dataIdx = data.findIndex((point) => point.x === value);
    x = value;
  } else {
    dataIdx = findCatIdx(options, categories[0], category);
    x = dataIdx;
  }
  const { y } = data[dataIdx];
  const allowsStackedColumnRenderEndLine =
    attachTo === 'category' && options.type === 'column' && !!options.stacking;
  const [yAxis, yValue] =
    attachTo === 'point' || allowsStackedColumnRenderEndLine ? [0, y] : [undefined, undefined];
  const first = dataIdx === 0;
  const last = dataIdx === lastIdx(data);
  let ms;
  // if (irregularDates) ms = findMilliseconds(options, data);
  return [x, yValue, yAxis, { first, last }, ms];
}

function findSeries(series, seriesName) {
  return series.find(({ name }) => name.toString() === seriesName.toString());
}

function findValue(category, options) {
  const date = parseDate(category, options);
  return date[0].value;
}

function findCatIdx(options, categories, annotationCategory) {
  return categories.findIndex((currentCategory, i) =>
    compareCategory(options, currentCategory, annotationCategory, i)
  );
}

function compareCategory(options, category, annotationCategory, index) {
  return options.quarterlyResults
    ? checkQuarterly(options, category, annotationCategory, index)
    : category === annotationCategory;
}

function checkQuarterly(options, category, annotationCategory, index) {
  const date = parseDate(annotationCategory, options);
  const formattedCategory = formatQText(date[0], index);
  return formattedCategory === category;
}

function getAreaY(options, seriesIdx, dataIdx) {
  // for area charts, need to calculate top y for each section of stack
  const series = options.originalSeries;
  const totalForPercent = series.reduce(
    (total, currentSeries) => total + currentSeries[dataIdx].y,
    0
  );
  let seriesY = 0;
  for (let i = lastIdx(series); i >= seriesIdx; i--) {
    const { y } = series[i][dataIdx];
    const height = options.stacking === 'normal' ? y : (y / totalForPercent) * 100;
    seriesY += height;
  }
  return seriesY;
}

function lastIdx(arr) {
  return arr.length - 1;
}

export function findMilliseconds(options, series) {
  // for highstocks charts, calculate how many milliseconds equal shape's radius
  const delta = findDelta(series);
  const pxPerX = options.width / (series.length - 1);
  return delta / pxPerX;
}

function findDelta(series) {
  let prevDelta = 0;
  let currentDelta;
  for (let i = 1; i < series.length; i++) {
    currentDelta = series[i].x - series[i - 1].x;
    if (currentDelta === prevDelta) return currentDelta;
    prevDelta = currentDelta;
  }
}

function createShape(item, options, x, y, yAxis, isVideoGroup) {
  const shape = {};
  const point = { x, y, xAxis: 0, yAxis };
  const { print } = options;
  switch (item.lineEnd) {
    case 'circle':
      shape.r = circleR(print);
      // shape.strokeWidth = print ? 0.5 : 1;
      shape.fill = 'rgba(0, 0, 0, 0)';
      shape.stroke = '#fff';
      shape.point = point;
      break;
    case 'arrow':
      // transparent path ending with custom arrow (created in defs)
      shape.type = 'path';
      shape.markerEnd = `custom-arrow-${options.print ? `print` : `digital`}`;
      shape.id = 'shape';
      shape.points = [
        { x, y: 0, xAxis: 0 },
        { x, y, xAxis: 0, yAxis },
      ];
      break;
    case 'nothing':
      // transparent shape used for drawing line in postrender
      shape.point = point;
      break;
    default:
      // dot
      shape.r = isVideoGroup ? 9 : dotR(print);
      // shape.fill = isVideoGroup ? '#fff' : undefined;
      shape.point = point;
  }
  return shape;
}

function circleR(print) {
  return print ? 3.25 : 6.5;
}

function dotR(print) {
  return print ? 1.25 : 3;
}

function findMinMax(options, item, spacing, endPoints, ms) {
  const { print, irregularDates } = options;
  const { lineEnd } = item;
  const r = getRadius(print, lineEnd);
  if (endPoints.first) {
    spacing.min = r;
  } else if (endPoints.last) {
    spacing.max = irregularDates ? ms * (r + 2) : r;
  }
}

function getRadius(print, lineEnd) {
  switch (lineEnd) {
    case 'dot':
      return dotR(print);
    case 'circle':
      return circleR(print);
    case 'arrow':
      return arrowWidth(print) / 2;
  }
}

function addSpacing(data, options, extraSpace) {
  if (data.legend.enabled && (options.legend.layout === 'top' || options.forceLegendTop)) {
    const additionalSpacing = extraSpace.top + options.styles.annotationsPadding;
    data.legend.margin += additionalSpacing;
  } else {
    const { units, showRecessions, showUkRecessions } = options.axes;
    const extraSuffixRecessionSpace =
      units.suffix.length > 2 || showRecessions || showUkRecessions ? 10 : 0;
    data.chart.spacing[0] += extraSpace.top + extraSuffixRecessionSpace; // for multiline annotations
  }
  // for shapes for 1st or last data point, add sufficient padding
  const padding = setPadding(data, options, extraSpace);
  padding('min', 'max');
}

function setPadding(data, options, extraSpace) {
  return function (...args) {
    args.forEach((type) => {
      const space = extraSpace[type];
      const { xAxis } = data;
      const { irregularDates, width } = options;
      if (space) {
        irregularDates
          ? (xAxis.overscroll += extraSpace.max || 0)
          : (xAxis[`${type}Padding`] = (space + 5) / width);
      }
    });
  };
}
