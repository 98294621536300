// WIP

/**
 * @typedef {Object} ChartlosOptions
 * @property { Publications } product - publication name
 * @property {boolean} print - whether crop size is print
 * @property {string} id - crop name
 * @property {string} type - chart type
 * @property {Object[]} annotations - annotations
 * @property {Object} legend - legend settings
 * @property {string} horizontalBarType - for bar charts - horizontal type
 * @property {'linear'|'logarithmic'} logScale - y axis scale type (linear or logarithmic)
 * @property {StyleOptions} styles - primarily styles for outer chart
 */

/**
 * @typedef {Object} SpacingOptions
 * @property {string} id - crop size
 * @property {boolean} hasAnnotation - whether annotation is present
 * @property {boolean} isLegendTop - whether legend is top positioned
 * @property {string} chartType - chart type
 * @property {string} horizontalBarType - horizonatal bar type for bar charts
 * @property {boolean} hasRecessions - whether recessions enabled
 */

/**
 * @typedef {Object} StyleOptions
 * @property {number} lineHeight
 * @property {number} hedLineHeight
 * @property {number} noteLineHeight
 * @property {number} annotationsLineHeight
 * @property {number} tickLength
 * @property {number} smallFontSize
 * @property {number} hedFontSize
 * @property {number} dekFontSize
 * @property {number} noteFontSize
 * @property {number} hedFontFamily
 * @property {number} noteFontFamily
 * @property {string} labelFontFamily
 * @property {string} color
 * @property {string} lightColor
 * @property {string} specialHedColor
 * @property {number} normalFontWeight
 * @property {number} boldFontWeight
 * @property {number} hedFontWeight
 * @property {number} dekFontWeight
 * @property {number} noteFontWeight
 * @property {Array.<string>} annotationsPalette
 * @property {number} annotationsPadding

 */

/**
 * @typedef {Object} outerTextStyle
 * @property {string} color
 * @property {string} fontFamily
 * @property {string} fontSize - with `px`
 * @property {number} fontWeight
 * @property {number} lineHeight
 * @property {string} width
 * @property {string} [letterSpacing] - with 'px'
 */

/**
 * @typedef {'wsj' | 'barrons' | 'marketwatch'| 'mansionglobal' | 'fnlondon' | 'djriskjournal'} Publications
 */

/**
 * @typedef {'headline' | 'description'} HeaderType
 */

/**
 * @typedef {'source' | 'note' | 'date'} FootnoteType
 */
