const WSJ_STYLES = {
  // font-family
  digitalFont: '"Retina", sans-serif',
  labelFont: 'Retina Narrow',
  videoFont: 'Retina Wide',
  // color
  normalColor: '#333',
  lightColor: '#666',
  videoNormalColor: '#fff',
  // font-weigth
  normalFontWeight: 300,
  videoNormalFontWeight: 400,
  boldFontWeight: 500,
  videoBoldFontWeight: 700,
  // hed font-size
  hedFontSize: 18,
  twitterVideoHedFontSize: 50,
  videoHedFontSize: 60,
  // dek font-size
  dekFontSize: 15,
  videoDekFontSize: 30,
  // note font-size
  noteFontSize: 13,
  videoNoteFontSize: 24,
  // line-height
  hedLineHeight: 20,
  twitterVideoHedLineHeight: 52,
  videoHedLineHeight: 62,
  noteLineHeight: 17,
  videoNoteLineHeight: 28,
  videoDekLineHeight: 40,

  // line height
  lineHeight: 16,
  printLineHeight: 8,
  videoLineHeight: 30,
  // annotations line height
  annotationsLineHeight: 24,
  printAnnotationsLineHeight: 8, // -?
  videoAnnotationsLineHeight: 52,
  // tick length
  tickLength: 5,
  printTickLength: 2.5,
  videoTickLength: 15,
  twitterVideoTickLength: 10,
  // font size
  normalFontSize: 13,
  printNormalFontSize: 7.5,
  videoNormalFontSize: 32,
  twitterVideoNormalFontSize: 30,
  smallFontSize: 11,
  printSmallFontSize: 7.5,
  // horizontal annotations color palette
  annotationsPalette: ['#323232', '#bebebe'],
  videoAnnotationsPalette: ['#999999', '#BA0303'],
  annotationsPadding: 20,
};

const BARRONS_STYLES = {
  // font-family
  digitalFont: '"Aileron", sans-serif',
  labelFont: '"Aileron", sans-serif',
  // color
  normalColor: '#000000', //
  lightColor: '#6F7878',
  // font-weigth
  normalFontWeight: 400,
  boldFontWeight: 700,
  hedFontSize: 22,
  printHedFontSize: 12.6,
  dekFontSize: 16,
  printDekFontSize: 8.4,
  noteFontSize: 14,
  printNoteFontSize: 6,
  // line-height
  hedLineHeight: 21,
  noteLineHeight: 18,
  lineHeight: 14, // +
  printLineHeight: 8,
  // annotations line height
  annotationsLineHeight: 21,
  printAnnotationsLineHeight: 16, // -?
  // tick length
  tickLength: 7,
  printTickLength: 2.5,
  // font size
  normalFontSize: 14,
  printNormalFontSize: 7.5,
  smallFontSize: 10,
  printSmallFontSize: 7.5,
  // horizontal annotations color palette
  annotationsPalette: ['#001E20', '#C6CCD1'],
  // barrons/chart/858bf07e647c01ddadb3c0ec
  annotationsPadding: 20,
};

const MARKETWATCH_STYLES = {
  // font-family
  digitalFont: '"Mulish", sans-serif',
  labelFont: '"Lato", sans-serif',
  // color
  hedColor: '#666F7D',
  normalColor: '#333333', // title
  lightColor: '#555555', // notes, dek
  // font-weigth
  normalFontWeight: 400,
  boldFontWeight: 800,
  semiboldFontWeight: 700,
  hedFontSize: 28,
  dekFontSize: 16,
  noteFontSize: 13,
  // line-height
  hedLineHeight: 34,
  noteLineHeight: 19,
  lineHeight: 25,
  // printLineHeight: 8, n/a
  // annotations line height
  annotationsLineHeight: 19, // same as regular lineheight
  // printAnnotationsLineHeight: 24, // n/a
  // tick length
  tickLength: 7,
  // printTickLength: 2.5, n/a
  // font size
  normalFontSize: 14,
  // printNormalFontSize: 7.5, n/a
  smallFontSize: 10,
  printSmallFontSize: 7.5,
  // horizontal annotations color palette
  annotationsPalette: ['#4d4d4d', '#919191'],
  annotationsPadding: 20,
};

const FNLONDON_STYLES = {
  // font-family
  digitalFont: '"Theinhardt", sans-serif',
  labelFont: '"Theinhardt", sans-serif',
  // color
  normalColor: '#4D6172',
  lightColor: '#70808C',
  specialHedColor: '#005879',
  // font-weight
  normalFontWeight: 400,
  boldFontWeight: 600,
  hedFontSize: 25,
  printHedFontSize: 12.6,
  dekFontSize: 17,
  printDekFontSize: 8.4,
  noteFontSize: 17,
  printNoteFontSize: 6,
  // line-height
  hedLineHeight: 28,
  noteLineHeight: 24,
  lineHeight: 24,
  printLineHeight: 8,
  // annotations line height
  annotationsLineHeight: 21,
  printAnnotationsLineHeight: 10, // -?
  // tick length
  tickLength: 7,
  printTickLength: 2.5,
  // font size
  normalFontSize: 14,
  printNormalFontSize: 7.5,
  smallFontSize: 10,
  printSmallFontSize: 7.5,
  // horizontal annotations color palette
  annotationsPalette: ['#203140', '#C8CCCD'],
  // barrons/chart/858bf07e647c01ddadb3c0ec
  annotationsPadding: 20,
  hedLetterSpacing: '-0.3px',
};

const MANSIONGLOBAL_STYLES = {
  // font-family
  digitalFont: 'Arial, sans-serif',
  labelFont: 'Arial, sans-serif',
  // color
  normalColor: '#585858', // hed, dek
  lightColor: '#777777', // notes, dek
  // font-weigth
  normalFontWeight: 400,
  boldFontWeight: 800, // TODO
  semiboldFontWeight: 600, // TODO
  hedFontSize: 17,
  dekFontSize: 17,
  noteFontSize: 14,
  // line-height
  hedLineHeight: 22,
  noteLineHeight: 16,
  lineHeight: 14,
  annotationsLineHeight: 22, // same as regular lineheight
  // tick length
  tickLength: 7, // TODO
  // font size
  normalFontSize: 14, // TODO
  smallFontSize: 10, // TODO
  // horizontal annotations color palette
  annotationsPalette: ['#4d4d4d', '#919191'], // TODO
  annotationsPadding: 20, // TODO
};

const RN_STYLES = {
  // font-family
  digitalFont: '"Noto Sans", sans-serif',
  labelFont: 'Noto Sans',
  // color
  normalColor: '#333',
  lightColor: '#666',
  videoNormalColor: '#fff',
  // font-weigth
  normalFontWeight: 300,
  videoNormalFontWeight: 400,
  boldFontWeight: 500,
  videoBoldFontWeight: 700,
  // hed font-size
  hedFontSize: 18,
  twitterVideoHedFontSize: 50,
  videoHedFontSize: 60,
  // dek font-size
  dekFontSize: 15,
  videoDekFontSize: 30,
  // note font-size
  noteFontSize: 13,
  videoNoteFontSize: 24,
  // line-height
  hedLineHeight: 20,
  twitterVideoHedLineHeight: 52,
  videoHedLineHeight: 62,
  noteLineHeight: 17,
  videoNoteLineHeight: 28,
  videoDekLineHeight: 40,

  // line height
  lineHeight: 16,
  printLineHeight: 8,
  videoLineHeight: 30,
  // annotations line height
  annotationsLineHeight: 24,
  printAnnotationsLineHeight: 8, // -?
  videoAnnotationsLineHeight: 52,
  // tick length
  tickLength: 5,
  printTickLength: 2.5,
  videoTickLength: 15,
  twitterVideoTickLength: 10,
  // font size
  normalFontSize: 13,
  printNormalFontSize: 7.5,
  videoNormalFontSize: 32,
  twitterVideoNormalFontSize: 30,
  smallFontSize: 11,
  printSmallFontSize: 7.5,
  // horizontal annotations color palette
  annotationsPalette: ['#323232', '#bebebe'],
  videoAnnotationsPalette: ['#999999', '#BA0303'],
  annotationsPadding: 20,
};
/**
 * these styles are used as part of options object
 * to set spacings, tick height, etc
 */
const STYLES = {
  // dek, label line height,
  lineHeight: {
    wsj: {
      'print-wsj': WSJ_STYLES.printLineHeight,
      video: WSJ_STYLES.videoLineHeight,
      verticalVideo: WSJ_STYLES.videoLineHeight,
      twitterVideo: WSJ_STYLES.videoLineHeight,
      default: WSJ_STYLES.lineHeight,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printLineHeight,
      default: BARRONS_STYLES.lineHeight,
    },
    rn: {
      default: RN_STYLES.lineHeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.lineHeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.lineHeight,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printLineHeight,
      default: FNLONDON_STYLES.lineHeight,
    },
  },
  hedLineHeight: {
    wsj: {
      video: WSJ_STYLES.videoHedLineHeight,
      verticalVideo: WSJ_STYLES.videoHedLineHeight,
      twitterVideo: WSJ_STYLES.twitterVideoHedLineHeight,
      default: WSJ_STYLES.hedLineHeight,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printLineHeight,
      default: BARRONS_STYLES.hedLineHeight,
    },
    rn: {
      default: RN_STYLES.hedLineHeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.hedLineHeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.hedLineHeight,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printLineHeight,
      default: FNLONDON_STYLES.hedLineHeight,
    },
  },
  dekLineHeight: {
    wsj: {
      video: WSJ_STYLES.videoDekLineHeight,
      verticalVideo: WSJ_STYLES.videoDekLineHeight,
      twitterVideo: WSJ_STYLES.videoDekLineHeight,
      default: WSJ_STYLES.hedLineHeight,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printLineHeight,
      default: BARRONS_STYLES.hedLineHeight,
    },
    rn: {
      default: RN_STYLES.hedLineHeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.lineHeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.lineHeight,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printLineHeight,
      default: FNLONDON_STYLES.lineHeight,
    },
  },
  noteLineHeight: {
    wsj: {
      video: WSJ_STYLES.videoNoteLineHeight,
      verticalVideo: WSJ_STYLES.videoNoteLineHeight,
      twitterVideo: WSJ_STYLES.videoNoteLineHeight,
      default: WSJ_STYLES.noteLineHeight,
    },
    rn: {
      'print-rn': RN_STYLES.printLineHeight,
      default: RN_STYLES.noteLineHeight,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printLineHeight,
      default: BARRONS_STYLES.noteLineHeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.noteLineHeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.noteLineHeight,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printLineHeight,
      default: FNLONDON_STYLES.noteLineHeight,
    },
  },
  annotationsLineHeight: {
    wsj: {
      'print-wsj': WSJ_STYLES.printAnnotationsLineHeight,
      video: WSJ_STYLES.videoAnnotationsLineHeight,
      verticalVideo: WSJ_STYLES.videoAnnotationsLineHeight,
      twitterVideo: WSJ_STYLES.videoAnnotationsLineHeight,
      default: WSJ_STYLES.annotationsLineHeight,
    },
    rn: {
      'print-rn': RN_STYLES.printAnnotationsLineHeight,
      default: RN_STYLES.annotationsLineHeight,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printAnnotationsLineHeight,
      default: BARRONS_STYLES.annotationsLineHeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.annotationsLineHeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.annotationsLineHeight,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printAnnotationsLineHeight,
      default: FNLONDON_STYLES.annotationsLineHeight,
    },
  },
  tickLength: {
    wsj: {
      'print-wsj': WSJ_STYLES.printTickLength,
      video: WSJ_STYLES.videoTickLength,
      verticalVideo: WSJ_STYLES.videoTickLength,
      twitterVideo: WSJ_STYLES.twitterVideoTickLength,
      default: WSJ_STYLES.tickLength,
    },
    rn: {
      'print-rn': RN_STYLES.printTickLength,
      default: RN_STYLES.tickLength,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printTickLength,
      default: BARRONS_STYLES.tickLength,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.tickLength,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.tickLength,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printTickLength,
      default: FNLONDON_STYLES.tickLength,
    },
  },
  normalFontSize: {
    wsj: {
      'print-wsj': WSJ_STYLES.printNormalFontSize,
      video: WSJ_STYLES.videoNormalFontSize,
      verticalVideo: WSJ_STYLES.videoNormalFontSize,
      twitterVideo: WSJ_STYLES.twitterVideoNormalFontSize,
      default: WSJ_STYLES.normalFontSize,
    },
    rn: {
      'print-rn': RN_STYLES.printNormalFontSize,
      default: RN_STYLES.normalFontSize,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printNormalFontSize,
      default: BARRONS_STYLES.normalFontSize,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalFontSize,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalFontSize,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printNormalFontSize,
      default: FNLONDON_STYLES.normalFontSize,
    },
  },
  smallFontSize: {
    wsj: {
      'print-wsj': WSJ_STYLES.printSmallFontSize,
      default: WSJ_STYLES.smallFontSize,
    },
    rn: {
      'print-rn': RN_STYLES.printSmallFontSize,
      default: RN_STYLES.smallFontSize,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printSmallFontSize,
      default: BARRONS_STYLES.smallFontSize,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.smallFontSize,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.smallFontSize,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printSmallFontSize,
      default: FNLONDON_STYLES.smallFontSize,
    },
  },
  hedFontSize: {
    wsj: {
      video: WSJ_STYLES.videoHedFontSize,
      verticalVideo: WSJ_STYLES.videoHedFontSize,
      twitterVideo: WSJ_STYLES.twitterVideoHedFontSize,
      default: WSJ_STYLES.hedFontSize,
    },
    rn: {
      'print-rn': RN_STYLES.printHedFontSize,
      default: RN_STYLES.hedFontSize,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printHedFontSize,
      default: BARRONS_STYLES.hedFontSize,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.hedFontSize,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.hedFontSize,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printHedFontSize,
      default: FNLONDON_STYLES.hedFontSize,
    },
  },
  dekFontSize: {
    wsj: {
      video: WSJ_STYLES.videoDekFontSize,
      verticalVideo: WSJ_STYLES.videoDekFontSize,
      twitterVideo: WSJ_STYLES.videoDekFontSize,
      default: WSJ_STYLES.dekFontSize,
    },
    rn: {
      'print-rn': RN_STYLES.printDekFontSize,
      default: RN_STYLES.dekFontSize,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printDekFontSize,
      default: BARRONS_STYLES.dekFontSize,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.dekFontSize,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.dekFontSize,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printDekFontSize,
      default: FNLONDON_STYLES.dekFontSize,
    },
  },
  noteFontSize: {
    wsj: {
      video: WSJ_STYLES.videoNoteFontSize,
      verticalVideo: WSJ_STYLES.videoNoteFontSize,
      twitterVideo: WSJ_STYLES.videoNoteFontSize,
      default: WSJ_STYLES.noteFontSize,
    },
    rn: {
      'print-rn': RN_STYLES.printNoteFontSize,
      default: RN_STYLES.noteFontSize,
    },
    barrons: {
      'print-barrons': BARRONS_STYLES.printNoteFontSize,
      default: BARRONS_STYLES.noteFontSize,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.noteFontSize,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.noteFontSize,
    },
    fnlondon: {
      'print-fnlondon': FNLONDON_STYLES.printNoteFontSize,
      default: FNLONDON_STYLES.noteFontSize,
    },
  },
  hedFontFamily: {
    wsj: {
      video: WSJ_STYLES.videoFont,
      verticalVideo: WSJ_STYLES.videoFont,
      twitterVideo: WSJ_STYLES.videoFont,
      default: WSJ_STYLES.digitalFont,
    },
    rn: {
      default: RN_STYLES.digitalFont,
    },

    barrons: {
      default: BARRONS_STYLES.digitalFont,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.digitalFont,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.digitalFont,
    },
    fnlondon: {
      default: FNLONDON_STYLES.digitalFont,
    },
  },
  noteFontFamily: {
    wsj: {
      video: WSJ_STYLES.videoFont,
      verticalVideo: WSJ_STYLES.videoFont,
      twitterVideo: WSJ_STYLES.videoFont,
      default: WSJ_STYLES.digitalFont,
    },
    rn: {
      default: RN_STYLES.digitalFont,
    },

    barrons: {
      default: BARRONS_STYLES.digitalFont,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.labelFont,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.labelFont,
    },
    fnlondon: {
      default: FNLONDON_STYLES.digitalFont,
    },
  },
  labelFontFamily: {
    wsj: {
      video: WSJ_STYLES.videoFont,
      verticalVideo: WSJ_STYLES.videoFont,
      twitterVideo: WSJ_STYLES.videoFont,
      default: WSJ_STYLES.labelFont,
    },
    rn: {
      default: RN_STYLES.digitalFont,
    },

    barrons: {
      default: BARRONS_STYLES.digitalFont,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.labelFont,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.labelFont,
    },
    fnlondon: {
      default: FNLONDON_STYLES.digitalFont,
    },
  },
  // includes hed, date(as of) color
  color: {
    wsj: {
      video: WSJ_STYLES.videoNormalColor,
      verticalVideo: WSJ_STYLES.videoNormalColor,
      twitterVideo: WSJ_STYLES.videoNormalColor,
      default: WSJ_STYLES.normalColor,
    },
    rn: {
      default: RN_STYLES.normalColor,
    },
    barrons: {
      default: BARRONS_STYLES.normalColor,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalColor,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalColor,
    },
    fnlondon: {
      default: FNLONDON_STYLES.normalColor,
    },
  },
  // includes notes color
  lightColor: {
    wsj: {
      default: WSJ_STYLES.lightColor,
    },
    rn: {
      default: RN_STYLES.lightColor,
    },
    barrons: {
      default: BARRONS_STYLES.lightColor,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.lightColor,
    },
    fnlondon: {
      default: FNLONDON_STYLES.lightColor,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.lightColor,
    },
  },
  // specialHedColor was created for fnlondon. Othe pubs inline with the 'color' prop
  specialHedColor: {
    wsj: {
      video: WSJ_STYLES.videoNormalColor,
      verticalVideo: WSJ_STYLES.videoNormalColor,
      twitterVideo: WSJ_STYLES.videoNormalColor,
      default: WSJ_STYLES.normalColor,
    },
    rn: {
      default: RN_STYLES.normalColor,
    },
    barrons: {
      default: BARRONS_STYLES.normalColor,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalColor,
    },
    fnlondon: {
      default: FNLONDON_STYLES.specialHedColor,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalColor,
    },
  },
  normalFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoNormalFontWeight,
      verticalVideo: WSJ_STYLES.videoNormalFontWeight,
      twitterVideo: WSJ_STYLES.videoNormalFontWeight,
      default: WSJ_STYLES.normalFontWeight,
    },
    rn: {
      default: RN_STYLES.normalFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.normalFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.normalFontWeight,
    },
  },

  boldFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoBoldFontWeight,
      verticalVideo: WSJ_STYLES.videoBoldFontWeight,
      twitterVideo: WSJ_STYLES.videoBoldFontWeight,
      default: WSJ_STYLES.boldFontWeight,
    },
    rn: {
      default: RN_STYLES.boldFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.boldFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.boldFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.semiboldFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.boldFontWeight,
    },
  },
  suffixFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoNormalFontWeight,
      verticalVideo: WSJ_STYLES.videoNormalFontWeight,
      twitterVideo: WSJ_STYLES.videoNormalFontWeight,
      default: WSJ_STYLES.normalFontWeight,
    },
    rn: {
      default: RN_STYLES.normalFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.normalFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.semiboldFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.normalFontWeight,
    },
  },
  hedFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoBoldFontWeight,
      verticalVideo: WSJ_STYLES.videoBoldFontWeight,
      twitterVideo: WSJ_STYLES.videoBoldFontWeight,
    },
    rn: {
      default: RN_STYLES.boldFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.boldFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.boldFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.semiboldFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.boldFontWeight,
    },
  },
  dekFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoNormalFontWeight,
      verticalVideo: WSJ_STYLES.videoNormalFontWeight,
      twitterVideo: WSJ_STYLES.videoNormalFontWeight,
    },
    rn: {
      default: RN_STYLES.normalFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.normalFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.normalFontWeight,
    },
  },
  noteFontWeight: {
    wsj: {
      video: WSJ_STYLES.videoNormalFontWeight,
      verticalVideo: WSJ_STYLES.videoNormalFontWeight,
      twitterVideo: WSJ_STYLES.videoNormalFontWeight,
      default: WSJ_STYLES.normalFontWeight,
    },
    rn: {
      default: RN_STYLES.normalFontWeight,
    },
    barrons: {
      default: BARRONS_STYLES.normalFontWeight,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.normalFontWeight,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.normalFontWeight,
    },
    fnlondon: {
      default: FNLONDON_STYLES.normalFontWeight,
    },
  },
  jaFontFamily: {
    default: 'Noto Sans JP, sans-serif',
  },
  chFontFamily: {
    default: 'Noto Sans TC, Noto Sans SC, sans-serif',
  },
  cjkBoldFontWeight: {
    default: 800,
  },
  annotationsPalette: {
    wsj: {
      video: WSJ_STYLES.videoAnnotationsPalette,
      verticalVideo: WSJ_STYLES.videoAnnotationsPalette,
      twitterVideo: WSJ_STYLES.videoAnnotationsPalette,
      default: WSJ_STYLES.annotationsPalette,
    },
    rn: {
      default: RN_STYLES.annotationsPalette,
    },
    barrons: {
      default: BARRONS_STYLES.annotationsPalette,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.annotationsPalette,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.annotationsPalette,
    },
    fnlondon: {
      default: FNLONDON_STYLES.annotationsPalette,
    },
  },
  annotationsPadding: {
    wsj: {
      default: WSJ_STYLES.annotationsPadding,
    },
    rn: {
      default: RN_STYLES.annotationsPadding,
    },
    barrons: {
      default: BARRONS_STYLES.annotationsPadding,
    },
    marketwatch: {
      default: MARKETWATCH_STYLES.annotationsPadding,
    },
    mansionglobal: {
      default: MANSIONGLOBAL_STYLES.annotationsPadding,
    },
    fnlondon: {
      default: FNLONDON_STYLES.annotationsPadding,
    },
  },
  hedLetterSpacing: {
    fnlondon: {
      default: FNLONDON_STYLES.hedLetterSpacing,
    },
    default: 'normal',
  },
  dekLetterSpacing: {
    default: 'normal',
  },
};

export default STYLES;
